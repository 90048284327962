import { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { go } from "logic/actions/navigation";
import PageContent from "Components/PageContent";
import { FACE_CAPTURE } from "logic/enums/pages";
import { isMobile } from "logic/deviceType";
import * as S from "./PageFaceCaptureInstructions.styles";
import { withTranslation } from "react-i18next";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import DaonButton from "Components/common/DaonButton";
import faceCaptureVideo from "assets/videos/face_capture.webp";
import InstructionVideo from "Components/common/InstuctionVideo";
import { setGyroscope } from "logic/actions/gyroscope";
import { logEvent, gyroscope_declined } from "logic/eventLogger";

export class PageFaceCaptureInstructions extends Component {
  static propTypes = {
    go: func,
    setGyroscope: func,
  };
  constructor(props) {
    super(props);
    this.state = {
      isGyroscopeCheckEnabled:
        props.shouldCheckGyroscope !='NONE',
      displayVideo: true,
    };
    if (!getTimePassed("face_capture")) startEventTimer("face_capture");
  }

  componentDidMount() {
    document.title = `${this.props.t("PageFaceCapture.title")} | Onboarding`;
    this.nextPage();
  }

  nextPage() {
    if (this.props.shouldSkipInstructionsPage) this.goToCapturePage();
  }

  goToCapturePage = () => {
   if (this.state.isGyroscopeCheckEnabled) {
      
      this.props.FaceCaptureInstance.isGyroscopeActive()
        .then((isGyroscopeActive) => {
          if(!isGyroscopeActive)  logEvent(gyroscope_declined);
          this.props.setGyroscope(isGyroscopeActive);
          this.props.go(FACE_CAPTURE);
        })
        .catch(() => {
          logEvent(gyroscope_declined);
          this.props.setGyroscope(false);
          this.props.go(FACE_CAPTURE);
        });
    } else {
     this.props.go(FACE_CAPTURE);
    }
  };

  updateDisplayProperty = () => {this.setState({ displayVideo: !this.state.displayVideo })}

  render() {
    return (
      <PageContent
        toggleLoading={this.state.pending}
        showBack={false}
        title={this.props.t("PageFaceCapture.title")}
        instructions={true}
        hideHeader={!!this.props.shouldSkipInstructionsPage}
      >
        {(!this.props.shouldSkipInstructionsPage &&
        <S.Content>
          <h2>{this.props.t("PageFaceCaptureInstructions.take_selfie")}</h2>
          {this.props.t("PageFaceCaptureInstructions.provide_selfie").trim() && <S.Paragraph margin="0 0 15px 0">
            {this.props.t("PageFaceCaptureInstructions.provide_selfie")}
          </S.Paragraph>}

          {isMobile(navigator.userAgent) && (
            <>
              {this.state.displayVideo &&<InstructionVideo
                video={faceCaptureVideo}
                alt={this.props.t("PageFaceCaptureInstructions.video_alt")}
                title={this.props.t("PageFaceCaptureInstructions.video_title")}
              ></InstructionVideo>}
               <S.ShowHideVideo onClick={this.updateDisplayProperty} id="display_video">
               {this.state.displayVideo ? this.props.t("Common.hide_video") : this.props.t("Common.display_video")}
              </S.ShowHideVideo>
            </>
            
          )}
          {this.props.t("PageFaceCaptureInstuctions.make_sure").trim() && <S.Para>{this.props.t("PageFaceCaptureInstuctions.make_sure")}</S.Para>}
          <S.ListItems>
            <ul>
              <li>{this.props.t("PageFaceCaptureInstuctions.tip_1")}</li>
              <li>
                {this.props.t("PageFaceCaptureInstructions.plain_background")}
              </li>
              <li>{this.props.t("PageFaceCaptureInstructions.remove_hats")}</li>
              <li>
                {this.props.t("PageFaceCaptureInstructions.neutral_expression")}
              </li>
              {this.props.t("PageFaceCaptureInstuctions.tip_5").trim() && <li>{this.props.t("PageFaceCaptureInstuctions.tip_5")}</li>}
            </ul>
          </S.ListItems>
          {this.state.error && <p className="error">{this.state.error}</p>}

          <DaonButton onClick={this.goToCapturePage} id="open_camera">
            {this.props.t("PageFaceCaptureInstructions.open_camera")}
          </DaonButton>
        </S.Content>)}
      </PageContent>
    );
  }
}
const componentWithTranslation = withTranslation()(PageFaceCaptureInstructions);
export default connect(
  (store) => {
    const { shouldCheckGyroscope } =
      store.configuration.extraConfig?.featureFlags;
    const shouldSkipInstructionsPage =
      store.configuration.extraConfig?.steps?.face?.options
        ?.shouldSkipInstructionsPage;
    return {
      shouldSkipInstructionsPage,
      livenessTestData: store.livenessTest.livenessTestData,
      shouldCheckGyroscope:
        shouldCheckGyroscope ||
        store.configuration.extraConfig?.steps?.face?.options
          ?.shouldCheckGyroscope ||
        "NONE",
      FaceCaptureInstance: store.faceCapture.FaceCaptureInstance,
    };
  },
  { go, setGyroscope }
)(componentWithTranslation);
